import { Route, Routes } from "react-router-dom";
import Login from "./scenes/Authentication/Login";
import PrivateRoutes from "./scenes/Authentication/PrivateRoutes";
import Bar from "./scenes/bar";
import CabinetMap from "./scenes/cabinetMap";
import Calendar from "./scenes/calendar/calendar";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Form from "./scenes/form";
import Geography from "./scenes/geography";
import Layout from "./scenes/Layout";
import Line from "./scenes/line";
import Payments from "./scenes/payments";
import Pie from "./scenes/pie";
import Swaps from "./scenes/swaps";
import Team from "./scenes/team";
import { Toaster } from "react-hot-toast";

function App() {


  return (
    <>
          <Routes>
          {/* Public routes */}
          <Route path="/cabinetmap" element={<CabinetMap />}/>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout/>}>
              {/* Private routes */}
              <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/swaps" element={<Swaps />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/geography" element={<Geography />} />
               </Route>
          </Route>
        </Routes>
        <Toaster />
    </>

  );
}

export default App;
